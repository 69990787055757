import React from 'react'
import { navigate } from "gatsby"
import styled, { keyframes } from 'styled-components'

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}
class ContactOneForm extends React.Component {
      constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            phone: "",
            message: "",
            error: false,
            status: ""
        }
        
    }

    handleSubmit = e => {
      console.log("Submit")
      
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...this.state })
      })
        .then(() => navigate('/thank-you'))
        .catch(error => alert(error));

      e.preventDefault();
    };

    handleChange = e => this.setState({ [e.target.name]: e.target.value });

    formSubmit(e) {
      console.log("Form submit")
      console.log(this.state)
      
      if (this.state.name === "" || this.state.email === "" || this.state.message === "") {
          this.setState({error: true})
          
      } else {
        e.preventDefault();

       /* fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "contact", ...this.state })
        })
          .then(() => alert("Success!"))
          .catch(error => alert(error));*/
         /* const form = e.target;
          const data = new FormData(form);
          const xhr = new XMLHttpRequest();
          console.log(form)
          
          xhr.open(form.method, form.action);
          xhr.setRequestHeader("Accept", "application/json");
          xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
              form.reset();
              console.log("Success send form")
              this.setState({ status: "SUCCESS" });
            } else {
              console.log("Error sending form")
              this.setState({ status: "ERROR" });
            }
          };
          xhr.send(data);*/
          this.setState({error: false})
      }
      this.forceUpdate() 
    }

    check(val) {
        if (this.state.error && val === "") {
            return false
        } else {
            return true
        }
    }

    shouldComponentUpdate(nextProps, nextState) { 
      return false;
    }

    
    render() {
        //const { name, email, message } = this.state;


        const Separator = styled.div`
            height: 5px;
            width: 50px;
            background-color: #e7225a;
            margin-bottom: 30px;
            margin-left: 6px;
        `

        const ContactForm = styled.form`
            padding: 40px;
            width: 100%;
        `

        const Heading = styled.h2`
            font-size: 39px;
            font-family: Teko;
            color: #fff;
        `

        const InputElement = styled.div`
            margin: 20px 0;
        `

        const ErrorInputAnimation = keyframes`
            0% {
              border-bottom: 1px solid #111;
            }
            100% {
              border-bottom: 1px solid #ff0000;
            }
        `
        

        const Input = styled.input`
            width: 100%;
            background-color: #111;
            border: none;
            border-bottom: 1px solid #fff;
            padding: 10px 5px;
            border-radius: 0;
            color: #fff;
            font-weight: 500;
            text-transform: lowercase;
            transition: .5s;
            &:focus {
              border-bottom: 1px solid #e7225a;
              outline: none;
            }
            &.error {
              animation: ${ErrorInputAnimation} 1s forwards;
            }
            &::placeholder {
              color: #fff;
            }
        `

        const Textarea = styled.textarea`
            width: 100%;
            background-color: #111;
            border: none;
            border-bottom: 1px solid #fff;
            padding: 10px 5px;
            border-radius: 0;
            text-transform: lowercase;
            font-weight: 500;
            color: rgb(255, 255, 255);
            transition: all 0.5s ease 0s;
            min-height: 100px;
            margin-top: 0px;
            margin-bottom: 0px;
            height: 100px;
            &:focus {
              border-bottom: 1px solid #e7225a;
              outline: none;
            }
            &::placeholder {
              color: #fff;
            }
        `

        const Submit = styled.button`
            display: block;
            height: 50px;
            width: 186px;
            position: relative;
            border: none;
            overflow: hidden;
            transition: .5s;
            float: right;
            &::after {
              position: absolute;
              content:'';
              display: inline-block;
              background: rgba(59,173,227,1);
              background: linear-gradient(45deg,rgb(134 5 36) 0%,#f44336 25%,#f44336 51%,rgb(105 1 31) 100%);
              height: 100%;
              width:  140%;
              top: 0;
              left: 0;
              transition: .5s;
            }
            
            &:hover {
              &::after {
                transform: translateX(-20%);
              }
            }
            span {
              position: relative;
              top: 0;
              color: #fff;
              z-index: 10;
              text-transform: uppercase;
              letter-spacing: 2px;
            }
        `

        return(
          <ContactForm data-netlify="true" name="contact" method="post" data-netlify-honeypot="bot-field" onSubmit={this.handleSubmit}>
              <Heading>
                Let´s create something awesome together.
              </Heading>
              <Separator />
              <InputElement>
                <Input type="text" name="name" defaultValue={this.state.name} className={`name ${this.check(this.state.name) ? "" : "error"}`} placeholder="Name" onChange={this.handleChange} />
              </InputElement>
              <InputElement>
                <Input type="text" name="email" defaultValue={this.state.email} className={`email ${this.check(this.state.email) ? "" : "error"}`} placeholder="Email" onChange={this.handleChange}/>
              </InputElement>
              <InputElement>
                <Textarea placeholder="Message" name="message" defaultValue={this.state.message} className={`message ${this.check(this.state.message) ? "" : "error"}`} onChange={this.handleChange} />
              </InputElement>              
              <input type="hidden" name="form-name" value="contact"></input>
              <Submit type="submit">
                <span>
                  Submit
                </span>
              </Submit>             
          </ContactForm>
        )
    }

}

export default ContactOneForm